import http from "../../shared/http";
import weatherForecastStorage from "../../storage/modules/weather-forecast";
import store from "../../store/index";

export async function downloadWeatherForecasts(cityId) {
  var forecastList = await http.get(
    `weatherForecasts/getlistbycityid?cityId=${cityId}`
  );
  if (forecastList) {
    await weatherForecastStorage.clearWeatherForecasts();
    await weatherForecastStorage.bulkAddWeatherForecast(forecastList);
    await store.dispatch("weather/loadWeatherForecasts");
  }
}

export default {
  downloadWeatherForecasts,
};
