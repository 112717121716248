const state = {
  item: null,
};

const mutations = {
  start(state, item) {
    state.item = item;
  },
  stop(state) {
    state.item = null;
  },
};

const actions = {
  async start({ dispatch, commit }, item) {
    await commit("start", item);
    dispatch("play", "Welcome", { root: true });
  },
  async stop({ commit }) {
    await commit("stop");
  },
};

const getters = {
  welcomeData: (state) => state.item,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
