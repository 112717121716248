export default {
  install(Vue) {
    Vue.directive("animate-letters", {
      /*
       * Usage:
       * v-animate-letters="{
       *   text: 'Optional text',         // if not provided, the directive uses el.textContent
       *   animationName: 'letterFadeIn', // the CSS animation name to use
       *   delayForNextLetter: 0.1,       // time (in seconds) between each letter
       *   durationOfEachLetter: 1        // duration (in seconds) of the animation per letter
       *   timingFunction: 'ease'
       * }"
       */
      inserted(el, binding) {
        const options = binding.value || {};

        // Get the element’s current text content (after Vue rendering)
        const innerText = el.textContent.trim();
        // If the element already has text, use that; otherwise, use options.text (if provided)
        const text = innerText || options.text || "";

        // Clear the element’s content
        el.innerHTML = "";

        // Create a span for each letter in the determined text
        Array.from(text).forEach((character, index) => {
          const span = document.createElement("span");
          span.classList.add("letter");

          // Apply the desired animation properties from options
          span.style.display = "inline-block";
          span.style.animationName = options.animationName;
          span.style.animationDelay = `${
            index * (options.delayForNextLetter || 0.1)
          }s`;
          span.style.animationDuration = `${
            options.durationOfEachLetter || 1
          }s`;
          span.style.animationFillMode = "both";
          span.style.animationTimingFunction = options.timingFunction || "ease";

          // Replace spaces with a non-breaking space for proper display
          span.innerHTML = character === " " ? "&nbsp;" : character;
          el.appendChild(span);
        });
      },
    });
  },
};
