import http from "../../shared/http";
import contentStorage from '../../storage/modules/content';
import utility from "../../shared/utils";
import store from "../../store/index";
import cacheHelper, { addFileToCache } from "../../shared/cache-helper";
import { checkContentTime, sortArrayByFileSize } from "../../shared/content-helper";
import playlistManager from "./playlist-manager";

import playlistStorage from '../../storage/modules/playlist';
export async function downloadContents() {
    try {
        let contents = await http.get("contents/getlistwithdetail");
        if (contents && contents.length) {
            let oldList = await contentStorage.getContents();
            await contentStorage.clearContents();
            let playlists = await playlistStorage.getPlaylists();
            await utility.asyncForEach(contents, async c => {
                let inList = checkContentInPlaylistByCategoryId(c.content.categoryId, playlists);
                if (inList) {
                    let filteredList = oldList
                        ? oldList.filter(u => u.categoryId === c.content.categoryId)
                        : null;
                    let minViewCount =
                        filteredList && filteredList.length ? filteredList[0].viewCount : 0;

                    let oldItem =
                        oldList && oldList.length ? oldList.find(u => u.id == c.content.id) : null;
                    let content = {
                        ...c.content,
                        viewCount: oldItem ? oldItem.viewCount : minViewCount,
                        files: c.contentFiles,
                        schedule: c.contentSchedule
                    };
                    await contentStorage.addContent(content);
                }
            });
        }
        else {
            let oldList = await contentStorage.getContents();
            if (oldList && oldList.length) {
                await contentStorage.clearContents();
            }
        }
        let contentList = await contentStorage.getContents();
        await clearUnUsedContentCaches(contentList);
        await saveContentsToCache(contentList);

    } catch (error) {
        window.console.log(error);
    }
}

export async function downloadImageFontsAndPositionsAndBackgrounds() {
    try {
        let imagePositionTypes = await http.get("contents/getimagepositiontypes");
        localStorage.setItem('imagePositionTypes', JSON.stringify(imagePositionTypes));
        let fontTypes = await http.get("contents/getimagefonts");
        localStorage.setItem('fontTypes', JSON.stringify(fontTypes));
        let backgroundTypes = await http.get("contents/getimagebackgroundtypes");
        localStorage.setItem('backgroundTypes', JSON.stringify(backgroundTypes));
    } catch (error) {
        window.console.log(error);
    }
}

export async function saveContentsToCache(contents) {
    if (!contents || !contents.length) return;
    contents = await sortArrayByFileSize(contents);
    return utility.asyncForEach(contents, async c => {
        var inPlayList =
            c.data && c.data.categoryName && c.data.categoryName.length > 0 ?
                await playlistManager.checkPlaylistByCategoryName(c.data.categoryName) : true;
        if (inPlayList) {
            if (c && c.data && c.data.files && c.data.files.length) {
                await utility.asyncForEach(c.data.files, async file => {
                    let fileName = process.env.VUE_APP_BASE_CDN_URL + "/" + file.fileName;
                    await addFileToCache(fileName);
                });
            }
        }
    });

}

function checkContentInPlaylistByCategoryId(categoryId, playlists) {
    if (!playlists || !playlists.length)
        return false;
    let filteredList = playlists.filter(u =>
        u.categoryId == categoryId
    );
    return filteredList && filteredList.length > 0;
}


export async function clearUnUsedContentCaches(contents) {
    let fileNames = [];
    if (contents && contents.length) {
        await utility.asyncForEach(contents, async c => {
            var inPlayList =
                c.data && c.data.categoryName && c.data.categoryName.length > 0 ?
                    await playlistManager.checkPlaylistByCategoryName(c.data.categoryName) : true;
            if (inPlayList) {
                if (c.data.files && c.data.files.length)
                    c.data.files.forEach(f =>
                        fileNames.push(process.env.VUE_APP_BASE_CDN_URL + "/" + f.fileName)
                    );
            }
        });
    }
    var logo = store.state && store.state.logo ? store.state.logo : null;
    let cacheList = await cacheHelper.getAllFiles();
    if (cacheList) {
        await utility.asyncForEach(cacheList, async f => {
            if (f.url.includes("Resources/Downloads") && !fileNames.includes(f.url)) {
                if (!logo || !f.url.includes(logo)) {
                    await cacheHelper.deleteByKey(f.url);
                }
            }
        });
    }
}

export async function downloadContentById(contentId) {
    var c = await http.get(`contents/getbyid?contentId=${contentId}`);
    if (c && c.content) {
        let minViewCountItem = await contentStorage.getFirstContentOrderByViewCount(
            c.content.categoryId
        );
        let minViewCount = minViewCountItem ? minViewCountItem.viewCount : 0;
        let content = {
            ...c.content,
            viewCount: minViewCount + 1,
            files: c.contentFiles,
            schedule: c.contentSchedule
        };
        if (content.files && content.files.length) {
            await utility.asyncForEach(content.files, async file => {
                let fileName = process.env.VUE_APP_BASE_CDN_URL + "/" + file.fileName;
                await addFileToCache(fileName);
            });
        }
        await contentStorage.addContent(content);
        if (
            content.schedule &&
            content.schedule.frequenceType &&
            content.schedule.frequenceType === "On"
        ) {
            if (checkContentTime(content)) {
                await store.dispatch("content/prepareContent", content);

            }
        }
    }
}
export async function checkAndDeactivateOneTimeContent() {
    try {

        let deactivateContentId = localStorage.getItem("deactivateContentId");
        if (!deactivateContentId) return;
        let contentId = parseInt(deactivateContentId);

        var content = await contentStorage.getContentById(contentId);
        if (content && content.data && content.data.files) {
            await utility.asyncForEach(content.data.files, async file => {
                await cacheHelper.deleteByKey(file.fileName);
            });
        }

        // await contentStorage.deleteContentById(contentId);
        // await http.post("contents/deactivate?contentId=" + contentId);
        // localStorage.removeItem("deactivateContentId");
    } catch (error) {
        window.console.log(error)
    }
}
export default {
    downloadContents,
    downloadContentById,
    checkAndDeactivateOneTimeContent,
    downloadImageFontsAndPositionsAndBackgrounds
}