import storage from "../../storage/modules/daily-horoscope";

const state = {
  name: "",
  comment: "",
  imagePath: "",
};

const mutations = {
  setState(state, data) {
    state.name = data.name.toUpperCase();
    state.comment = data.comment;
    state.imagePath = data.imagePath;
  },
};

const actions = {
  getImageByName(context, name) {
    if (name === "koc") return "koc.png";
    if (name === "boga") return "boga.png";
    if (name === "ikizler") return "ikizler.png";
    if (name === "yengec") return "yengec.png";
    if (name === "aslan") return "aslan.png";
    if (name === "basak") return "basak.png";
    if (name === "terazi") return "terazi.png";
    if (name === "akrep") return "akrep.png";
    if (name === "yay") return "yay.png";
    if (name === "oglak") return "oglak.png";
    if (name === "kova") return "kova.png";
    if (name === "balik") return "balik.png";
    return "";
  },

  async setContent({ dispatch, commit }) {
    let item = await storage.getFirstDailyHoroscopeOrderByViewCount();
    if (!item || !item.name || !item.comment) {
      dispatch("setNextPlaylist", null, { root: true });
      return;
    }

    await storage.updateDailyHoroscopeViewCount(item.id, item.viewCount + 1);

    commit("setState", {
      name: item.name,
      comment: item.comment,
      imagePath: await dispatch("getImageByName", item.name),
    });
    dispatch("play", "DailyHoroscope", { root: true });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
